<template>
    <div class="newsDet">
        <a href="javascript:void(0);" class="scrollT" ref="newsdet"></a>
        <div class="sub-banner" v-if="bannerList.length > 0" :style="{'backgroundImage':'url('+ bannerList[0].pic +')'}">
			<div class="container">
				<div class="main">
					<h2 class="wow fadeInDown">News</h2>
                    <div class="info wow fadeInUp">
						<div class="date">{{ detail.add_date }}</div>
						<div class="title">{{ detail.title }}</div>
						<a href="javascript:void(0);" @click="goBack" class="back"><i></i>Back to list</a>
					</div>
				</div>
			</div>
		</div>
        <div class="newsDet-main">
			<div class="container">
				<div class="content wow fadeInUp" v-html="detail.detail"></div>
				<div class="news-page wow fadeInUp">
                    <router-link replace :to="`/newdet?id=${prevInfo.id}`" v-if="prevInfo.id">
                        <span>Previous article:</span> 
                        {{ prevInfo.title }}
                    </router-link>
                    <a v-else href="javascript:void(0);"><span>Previous article:</span> without news</a>
                    <router-link replace :to="`/newsdet?id=${nextInfo.id}`" v-if="nextInfo.id">
                        <span>Next article:</span>
                        {{ nextInfo.title }}
                    </router-link>
                    <a v-else href="javascript:void(0);"><span>Next article:</span> without news</a>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
    import { WOW } from 'wowjs'
    import { getNewsInfo, getBannerList } from '../../request/api'
    export default {
        name:'NewsDet',
        metaInfo:{
            title:"DMEGC Solar-news",
            meta: [{                 // set meta
                name: 'keyWords',
                content: 'DMEGC Solar'
            },{
                name:'description',
                content:'DMEGC Solar'
            }]
        },
        props:[ 'id' ],
        data(){
            return {
                detail:{},
                nextInfo:{},
                prevInfo:{},
                bannerList:[]
            }
        },
        mounted(){
            getBannerList({cate:'news'}).then( res => {

                this.bannerList = res.data
            })
            this.showNewsDetData();
        },
        watch: {
            $route:function(){

                this.showNewsDetData();
            }
        },
        methods: {
            goBack(){ //返回新闻列表

                console.log(this.$router)

                this.$router.back()
            },
            showNewsDetData(){

                getNewsInfo({ id: this.id }).then( res => {

                    this.detail = res.data.detail
                    this.prevInfo = res.data.prevInfo
                    this.nextInfo = res.data.nextInfo
                    this.$nextTick(() => {

                        const wow = new WOW({
                            animateClass: 'animated',
                            offset: 100
                        })
                        wow.init();
                        this.$refs.newsdet.scrollIntoView();
                    });
                })
            }
        } 
    }
</script>

<style lang="scss" scoped>
.newsDet{
    position: relative;
    .info{
        position: relative;
        .date{
            font-size: 0.9375vw;
            line-height: 1.1458vw;
            color: #fff;
            font-style: italic;
            font-weight: 600;
        }
        .title{
            font-size: 1.875vw;
            line-height: 2.1875vw;
            color: #fff;
            width: 57.2917vw;
            margin-top: 0.625vw;
        }
        .back{
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            width: 8.3333vw;
            height: 2.6042vw;
            position: absolute;
            right: 0;
            top: 1.0417vw;
            color: #fff;
            background: #E60011;
            font-size: 0.8333vw;
            i{
                display: block;
                width: 1.25vw;
                height: 1.25vw;
                background: url('../../assets/images/arrowR.png') no-repeat;
                background-size: cover;
                transform: rotate(180deg);
                margin-right: 4px;
            }
        }
    }
    .newsDet-main{
        padding: 3.125vw 0 4.6875vw;
        .content{
            font-size: 1.0417vw;
            line-height: 1.5625vw;
            color: rgba(0,0,0,0.6);
            font-weight: 300;
            width: 68.75vw;
            margin: 0 auto;
            /deep/p{
                margin-bottom: 1.0417vw;
                font-size: 1.0417vw;
                font-weight: 300;
            }
            /deep/img{
                max-width: 100%;
            }
        }
        .news-page{
            margin-top: 2.1875vw;
            padding-top: 1.0417vw;
            border-top: 1px solid #CCD2DF;
            a{
                display: block;
                font-size: 1.0417vw;
                line-height: 1.5625vw;
                color: rgba(0,0,0,0.6);
                margin-bottom: 0.5208vw;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            span{
                font-weight: bold;
                color: rgba(0,0,0,0.8);
            }
        }

    }
}

@media screen and (max-width: 750px) {
    .newsDet{
        .sub-banner{
            .main{
                padding-bottom: .4rem;
            }
        }
        .info{
            .date{
                font-size: .22rem;
                line-height: .42rem;
            }
            .title{
                font-size: .28rem;
                line-height: .44rem;
                width: 100%;
                margin-top: .2rem;
            }
            .back{
                position: static;
                width: 2.6rem;
                height: .6rem;
                margin-top: .2rem;
                i{
                    width: .3rem;
                    height: .3rem;
                    margin-right: .1rem;
                }
            }
        }
        .newsDet-main{
            padding: .8rem 0;
            .content{
                width: 100%;
                font-size: .26rem;
                line-height: .44rem;
                /deep/p{
                    margin-bottom: .2rem;
                    font-size: .26rem;
                }
            }
        }
    }
}
</style>